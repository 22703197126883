<template>
  <div
    class="ChecklistView"
    :style="{maxHeight: `calc(100vh - ${$vuetify.breakpoint.mdAndUp ? 208 : 200}px)`}"
    :class="{ empty: !checklists.length }"
  >
    <ChecklistDefaultView
      v-if="checklists.length"
      class="ChecklistView__default-view"
      :project-id="projectId"
      :checklists="checklists"
      :open-checklist-id="openChecklistId"
      @select-checklist="selectChecklist"
    />
    <div
      v-else
      class="ChecklistView__empty-state"
    >
      <EmptyState />
    </div>
  </div>
</template>

<script>
import ChecklistDefaultView from '@/components/ChecklistDefaultView'
import EmptyState from '@/components/EmptyState'
import { CHECKLIST_POLL_INTERVAL } from '@/constants'

export default {
  name: 'ChecklistView',

  components: {
    ChecklistDefaultView,
    EmptyState,
  },

  props: {
    projectId: { type: String, required: true },

    // see `ChecklistSchema`; `store/schema:Checklist`
    checklists: { type: Array, required: true },

    // to style active checklist
    openChecklistId: { type: String, default: null },

    // list of selected rows for v-model
    value: { type: Array, default: () => [] },
  },

  data() {
    return {
      // checklist checked with checkboxes
      selectedChecklists: [],
      pollIntervalId: '',
    }
  },

  created() {
    const { projectId } = this

    this.pollIntervalId = setInterval(
      () =>
        this.pollIntervalId != null &&
        this.$store.dispatch('checklist/getChecklist', { projectId }),
      CHECKLIST_POLL_INTERVAL,
    )
  },

  beforeDestroy() {
    this.stopPolling()
  },

  methods: {
    stopPolling() {
      clearInterval(this.pollIntervalId)
      this.pollIntervalId = null
    },

    selectChecklist(checklist) {
      const { selectedChecklists } = this

      if (!selectedChecklists.includes(checklist.checklist.uuid)) selectedChecklists.push(checklist.checklist.uuid)
    },
  },
}
</script>

<style scoped lang="sass">
 .ChecklistView
   background: #ffffff
   position: relative
   overflow: auto

   &.empty
     background: #fafafc

   &__default-view

   &__empty-state
     padding: 20px 0 0 55px
</style>
