<template>
  <AppBarLayout
    class="ChecklistAppBar"
  >
    <template #header>
      {{ $t('checklist.Checklists') }}
    </template>

    <template #actions>
      <v-btn
        v-if="checklistId"
        key="'save-new-card'"
        color="primary"
        depressed
        class="actions__save-new-card-btn"
        :to="createCardRoute"
      >
        <v-icon
          class="mr-2"
          v-text="'mdi-plus'"
        />
        {{ $t('checklist.Card') }}
      </v-btn>
    </template>
  </AppBarLayout>
</template>

<script>
import AppBarLayout from '../layouts/AppBarLayout'

export default {
  name: 'ChecklistAppBar',

  components: {
    AppBarLayout,
  },

  props: {
    projectId: { type: String, required: true },
    checklistId: { type: String, default: null },
  },

  computed: {
    createCardRoute() {
      const { $route, projectId, checklistId } = this

      return {
        name: 'ProjectEditCard',
        params: { projectId },
        query: { checklistId, back: $route.fullPath },
      }
    },
  },
}
</script>
