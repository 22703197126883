<template>
  <div class="ChecklistsList">
    <ChecklistAppBar
      :project-id="projectId"
      :checklist-id="checklistId"
    />

    <div
      class="ChecklistsList__content d-flex"
      :class="{ 'pr-0': !!checklistId }"
    >
      <div
        class="ChecklistsList__checklists"
        :style="{maxHeight: `calc(100vh - ${$vuetify.breakpoint.mdAndUp ? 64 : 56}px)`}"
      >
        <div
          v-if="checklists && checklists.length"
          class="ChecklistsList__header"
        >
          <div class="ChecklistsList__header-progress">
            <h1>{{ $t('checklist.Checklists') }}</h1>
            <div class="ChecklistsList__progress">
              <ChecklistProgress
                v-if="checklists && checklists.length"
                class="ChecklistsList__progress-component"
                :checklist="checklists"
                justify-content="flex-end"
                :margin-right="30"
                status-position="absolute"
                status-top="0"
                status-right="0"
                :text="$t('checklist.itemsChecked')"
                background="#ffffff"
                :is-tooltip-disable="false"
              />
            </div>
          </div>
          <div class="ChecklistsList__header-filters">
            <v-btn
              tile
              text
              height="47px"
              style="font-weight: 400; min-width: 120px"
              @click="foldAll"
            >
              {{ collapseStatus ? $t('checklist.Expand') : $t('checklist.Collapse') }}
              <v-icon color="#C1C1D2">
                {{ collapseStatus ? 'mdi-unfold-more-horizontal' : 'mdi-unfold-less-horizontal' }}
              </v-icon>
            </v-btn>

            <span class="ml-4 mr-2">{{ $t('checklist.Show') }}</span>

            <v-btn
              class="ChecklistsList__header-filter-button"
              :class="{ active: checklistDoneFilter === 'all' }"
              tile
              text
              style="font-weight: 400; min-width: 46px"
              :ripple="false"
              height="47px"
              @click="changeDoneFilter('all')"
            >
              {{ $t('checklist.All') }}
            </v-btn>

            <v-btn
              v-for="done in CHECKLIST_DONE_STATUSES"
              :key="done"
              :disabled="isActive(done)"
              class="ChecklistsList__header-filter-button"
              :class="{ active: checklistDoneFilter === done }"
              tile
              icon
              height="47px"
              :ripple="false"
              @click="changeDoneFilter(done)"
            >
              <ChecklistDoneStatus :done="done" />
            </v-btn>
          </div>
        </div>
        <ChecklistView
          class="ChecklistView__checklists"
          :project-id="projectId"
          :checklists="filteredChecklist"
          :open-checklist-id="checklistId"
          :style="{
            flexBasis: checklistId ? `calc(100% - ${checklistDrawerWidth}px)` : null,
            maxWidth: checklistId ? `100%` : null,
          }"
        />
      </div>
      <v-navigation-drawer
        class="ChecklistsList__checklist-drawer"
        :class="{ 'ChecklistsList__checklist-drawer--open': !!checklistId }"
        :value="!!checklistId"
        :width="checklistDrawerWidth"
        :style="{
          transitionDuration: '200ms',
          transitionTimingFunction: 'ease-in-out',
          maxHeight: `calc(100vh - ${$vuetify.breakpoint.mdAndUp ? 64 : 56}px)`,
          minHeight: `calc(100vh - ${$vuetify.breakpoint.mdAndUp ? 64 : 56}px)`,
          maxWidth: $vuetify.breakpoint.name === 'lg' ? '400px' : '540px'
        }"
        stateless
        right
      >
        <InlineChecklist
          v-if="!!checklistId"
          :checklist-id="checklistId"
          :project-id="projectId"
          :flatten-checklist-uuid="checklistUuidFlattenList"
        />
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'

import { CHECKLIST_DONE_STATUSES } from '@/constants'

import defer from '../mixins/defer'
import keyboardNavigation from '../mixins/keyboardNavigation'

import ChecklistAppBar from '../components/ChecklistAppBar'
import ChecklistDoneStatus from '../components/ChecklistDoneStatus'
import ChecklistProgress from '../components/ChecklistProgress'
import ChecklistView from '../components/ChecklistView'
import InlineChecklist from '../components/InlineChecklist'

export default {
  name: 'ChecklistsList',

  components: {
    ChecklistAppBar,
    ChecklistDoneStatus,
    ChecklistProgress,
    ChecklistView,
    InlineChecklist,
  },

  mixins: [
    // FIXME: most likely not needed
    defer(2),

    keyboardNavigation({
      preventDefault: true,

      props: {
        upRoute: 'previousRoute',
        downRoute: 'nextRoute',
      },
    }),
  ],

  beforeRouteLeave(to, from, next) {
    const preserveRoutes = [
      'ChecklistsList',
      'ChecklistsListChecklist',
      'EditCard',
    ]
    if (!preserveRoutes.includes(to.name)) {
      this.$store.commit('checklist/reset')
      this.$store.commit('$snackbar/hide')
    }
    next()
  },

  props: {
    // checklists are always bound to a project
    projectId: { type: String, required: true },
    // to open inline checklist preview with the given `checklistId`
    checklistId: { type: String, default: null },
  },

  data() {
    return {
      CHECKLIST_DONE_STATUSES,
      flattenChecklistUuid: [],
    }
  },

  computed: {
    flattenChecklist() { return this.$store.state.checklist.byId },

    checklists() { return this.$store.state.checklist.checklists },

    checklistDrawerWidth() {
      return this.$vuetify.breakpoint.mdAndDown ? 400 : 540
    },

    currentPosition() { // Maybe<int>
      const { $route, checklistUuidFlattenList } = this

      if (!checklistUuidFlattenList) return null
      const pos = checklistUuidFlattenList.indexOf($route.params.checklistId)
      return pos === -1 ? null : pos
    },

    previousRoute() {
      const { $route, checklistUuidFlattenList, currentPosition: ix } = this
      if (!checklistUuidFlattenList || !ix || ix <= 0) return null

      const previousChecklistId = checklistUuidFlattenList[ix - 1]
      return R.pipe(
        R.assocPath(['params', 'checklistId'], previousChecklistId),
        R.pick(['name', 'params', 'checklistId']),
      )($route)
    },

    nextRoute() {
      const { $route, checklistUuidFlattenList, currentPosition: ix } = this
      if (!checklistUuidFlattenList || ix === null || ix >= (checklistUuidFlattenList.length - 1)) return null

      const previousChecklistId = checklistUuidFlattenList[ix + 1]
      return R.pipe(
        R.assocPath(['params', 'checklistId'], previousChecklistId),
        R.pick(['name', 'params', 'checklistId']),
      )($route)
    },

    checklistDoneFilter: {
      get() {
        return this.$store.state.checklist.checklistDoneFilter
      },
      set(done) {
        this.$store.state.checklist.checklistDoneFilter = done
      },
    },

    checklistUuidFlattenList() {
      this.flatChecklistNode(this.filteredChecklist)
      return this.flattenChecklistUuid
    },

    filteredChecklist() {
      if (!this.checklists?.length) return []

      if (this.$route?.query?.filter) {
        return [this.$store.state.checklist.byId[this.$route?.query?.filter]]
      }

      this.resetFlattenUuid()

      return R.clone(this.checklists)?.filter(this.filterByDoneField)
    },

    collapseStatus() {
      const { flattenChecklist } = this

      return Object.values(flattenChecklist).some(checklist => checklist.$folded)
    },
  },

  created() {
    const { projectId } = this

    this.$store.dispatch('checklist/getChecklist', { projectId })
  },

  methods: {
    resetFlattenUuid() {
      this.flattenChecklistUuid = []
    },

    changeDoneFilter(done) {
      if (this.checklistDoneFilter && this.checklistDoneFilter === done) {
        this.checklistDoneFilter = ''
        return
      }
      this.checklistDoneFilter = done
    },

    foldAll() {
      const { collapseStatus } = this

      if (collapseStatus) return this.changeFoldStatusForAllChecklists(false)
      return this.changeFoldStatusForAllChecklists(true)
    },

    changeFoldStatusForAllChecklists(foldStatus) {
      Object.keys(this.$store.state.checklist.byId).forEach(checklistId => {
        this.$store.state.checklist.byId[checklistId].$folded = foldStatus
      })
    },

    isActive(done) {
      if (!this.checklists.length) return true
      return !Object.values(this.$store.state.checklist.byId).some(checklist => checklist.done === done)
    },

    filterByDoneField(checklists) {
      const { checklistDoneFilter } = this
      if (checklistDoneFilter === 'done') return this.filterByKeyValue(checklists, 'done', 'done')
      if (checklistDoneFilter === 'not_done') return this.filterByKeyValue(checklists, 'done', 'not_done')
      if (checklistDoneFilter === 'indeterminate') return this.filterByKeyValue(checklists, 'done', 'indeterminate')
      if (checklistDoneFilter === 'inapplicable') return this.filterByKeyValue(checklists, 'done', 'inapplicable')

      return checklists
    },

    flatChecklistNode(checklist) {
      if (checklist?.$folded) {
        if (!this.flattenChecklistUuid.find(uuid => uuid === checklist.uuid)) {
          this.flattenChecklistUuid.push(checklist.uuid)
        }
      }

      if (Array.isArray(checklist)) {
        checklist.map(child => {
          if (!this.flattenChecklistUuid.find(uuid => uuid === child.uuid)) {
            this.flattenChecklistUuid.push(child.uuid)
          }

          if (child?.children?.length && !child?.$folded) this.flatChecklistNode(child.children)
        })
      }

      if (!this.flattenChecklistUuid.find(uuid => uuid === checklist.uuid) && checklist.uuid) {
        this.flattenChecklistUuid.push(checklist.uuid)
      }
    },

    filterByKeyValue(node, filterKey, filterValue) {
      return Object.keys(node).some(key => {
        if (key === filterKey && node[filterKey] === filterValue) {
          return true
        }

        if (Array.isArray(node.children)) {
          node.children = node.children.filter(this.filterByDoneField)
          return node.children.length
        }

        return null
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

@function trans($prop, $duration: 200ms, $timing: ease-in-out)
  @return $prop $duration $timing

.ChecklistsList

  &__content
    position: relative
    transition: trans(padding-right)
    will-change: padding-right

  &__header
    &-progress
      display: flex
      height: 96px
      align-items: center
      border-bottom: 1px solid rgba(0, 0, 0, 0.12)
      padding-left: 55px

    &-filters
      display: flex
      height: 48px
      align-items: center
      border-bottom: 1px solid rgba(0, 0, 0, 0.12)
      padding-left: 25px
      background: #ffffff
      position: relative
      z-index: 4

    &-filter-button
      &.active
        background: #F8F8FD
        color: #0288D1

        &:after
          content: ''
          position: absolute
          top: -2px
          background: #0288D1
          height: 2px
          width: 100%

  &__progress
    width: 100%
    position: relative
    height: 24px
    margin-left: 60px

    &-component
      width: 100%
      border-radius: 4px
      border: 1px solid rgba(0, 0, 0, 0.12)

  &__checklists
    flex-grow: 1
    transition: trans(flex-basis), trans(max-width) !important
    will-change: flex-basis, max-width
    overflow: hidden

  &__checklist-drawer
    height: auto !important
    flex: 0 0 0

  &__checklist-drawer--open
    transition: trans(flex-basis) !important
    flex: 0 0 540px

    @media #{map-get($display-breakpoints, 'md-and-down')}
      flex: 0 0 400px

  &__snackbar-task-btn
    ::v-deep .v-btn__content
    font-size: 16px
    line-height: 24px
    font-weight: 500
</style>
