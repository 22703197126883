<template>
  <div class="ChecklistAssignedObjects">
    <div
      class="ChecklistAssignedObjects__ip"
    >
      <ChecklistDoneStatus
        class="ChecklistAssignedObjects__status"
        :done="object.connectionDone"
      />
      <div class="ChecklistAssignedObjects__name">
        {{ object.connectedNode.name }}
      </div>
    </div>

    <div
      v-if="object.ports && object.ports.length"
      class="ChecklistAssignedObjects__port"
    >
      <ChecklistAssignedObjects
        v-for="port in object.ports"
        :key="port.connectedUuid"
        :object="port"
      />
    </div>

    <div
      v-if="object.hostnames && object.hostnames.length"
      class="ChecklistAssignedObjects__hostname"
    >
      <ChecklistAssignedObjects
        v-for="hostname in object.hostnames"
        :key="hostname.connectedUuid"
        :object="hostname"
      />
    </div>
  </div>
</template>

<script>
import ChecklistDoneStatus from '@/components/ChecklistDoneStatus'

export default {
  name: 'ChecklistAssignedObjects',

  components: {
    ChecklistDoneStatus,
  },

  props: {
    object: { type: Object, required: true },
  },
}
</script>

<style lang="sass" scoped>
  .ChecklistAssignedObjects

    &__status
      position: absolute
      left: 30px

    &__name
      margin-left: 34px
      font-size: 13px

    &__ip
      display: flex
      align-items: center
      height: 36px

    &__port
      margin-left: 20px

    &__hostname
      margin-left: 20px
</style>
