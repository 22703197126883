export default (count = 10) => ({
  data() {
    return {
      displayPriority: 0,
    }
  },

  mounted() {
    this.runDisplayPriority()
  },

  methods: {
    runDisplayPriority() {
      const step = () => {
        requestAnimationFrame(() => {
          this.displayPriority++
          if (this.displayPriority < count) {
            step()
          }
        })
      }
      step()
    },

    defer(priority) {
      if (process.env.NODE_ENV === 'test') return true
      return this.displayPriority >= priority
    },
  },
})
